<template>
  <b-container class="d-flex flex-column px-0 px-md-1 px-lg-1">
    <template>
      <app-timeline>
        <app-timeline-item
          v-for="(DhlTrack, index) in DhlTrack"
          :key="index"
          :title="DhlTrack.description"
          :subtitle="''"
          :time="DhlTrack.dateTime"
          variant="warning"
        />
      </app-timeline>
    </template>
  </b-container>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import axios from "@axios";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: { shipmentid: {} },
  data() {
    return {
      DhlTrack: [],
    };
  },
  mounted() {
    axios
    this.$http.get(
        `dhl_tracking/${this.shipmentid}`
      )
      .then((response) => (this.DhlTrack = response.data.data));
  },
};
</script>

<style scoped></style>
