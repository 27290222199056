<template>
  <b-container class="d-flex flex-column px-0 px-md-1 px-lg-1">
    <img
      :src="require('@/assets/images/image4.png')"
      alt="DHL LABEL"
      class="DhlLabel"
    />
  </b-container>
</template>

<script>
import { BContainer } from "bootstrap-vue";
import store from "@/store/index";

export default {
  components: {
    BContainer,
  },

  data() {
    return {
 
    };
  },

  computed: {
  },
};
</script>

<style scoped>
.DhlLabel{
    height: 700px;
}
</style>
