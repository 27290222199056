<template>
  <b-overlay :show="isSubmitting" rounded="sm">
    <b-container class="col">
      <b-form-group label="Select Date" class="mb-2">
        <flat-pickr
          v-model="date"
          :config="{
            dateFormat: 'Y-m-d',
            defaultDate: 'today',
          }"
          class="form-control font-small-2 w-auto"
          placeholder="Choose Date"
          readonly="readonly"
          @input="getOrders(date)"
        />
      </b-form-group>
      <b-card>
        <MyTable
          :columns="columns"
          :rows="rows"
          :is-loading="isLoading"
          :searchEnabled="true"
        >
          <template v-slot="{ props }">
            <span v-if="props.column.field == 'id'">
              <b-button variant="gradient-primary" block>{{
                props.row.id
              }}</b-button>
            </span>
            <span
              v-else-if="props.column.field === 'sender_location.first_name'"
            >
              {{ props.row.sender_location.first_name }}
              {{ props.row.sender_location.last_name }}
            </span>
            <span
              v-else-if="props.column.field === 'recipient_location.first_name'"
            >
              {{ props.row.recipient_location.first_name }}
              {{ props.row.recipient_location.last_name }}
            </span>
            <span v-else-if="props.column.field === 'amount'">
              RM {{ props.row.amount }}
            </span>
            <span v-else-if="props.column.field == 'status'">
              <b-button
                v-if="props.row.status === 'Active'"
                block
                variant="success"
              >
                Active
              </b-button>
              <b-button
                v-else-if="props.row.status === 'Generated'"
                block
                variant="warning"
              >
                Generated
              </b-button>
              <b-button v-else block variant="danger"> Cancelled </b-button>
            </span>
            <span v-else-if="props.column.field === 'action'">
              <b-dropdown
                v-if="props.row.status === 'Active'"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="generatedhl(props.row.id, props.row.originalIndex)"
                >
                  <span>Generate Label</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item
                @click="cancelOrder(props.row.id, props.row.originalIndex)"
              >
                <span>Cancel</span>
              </b-dropdown-item> -->
              </b-dropdown>
              <b-dropdown
                v-else-if="props.row.status === 'Generated'"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="generatedhllabel(props.row.label)">
                  <span>Download Label</span>
                </b-dropdown-item>
                <b-dropdown-item @click="generatedhltracking(props.row)">
                  <span>DHL Tracking</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click="canceldhlgenerate(props.row)">
                <span>Cancel</span>
              </b-dropdown-item> -->
              </b-dropdown>
              <b-dropdown
                v-else
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <span>No actions available</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </MyTable>
      </b-card>
      <!-- Generate DHL Tracking-->
      <b-modal
        ref="generatedhltracking"
        hide-footer
        hide-title
        title="Tracking Information"
        centered
        size="lg"
      >
        <DHLTracking :shipmentid="generateDHLid" />
      </b-modal>
      <!-- Generate DHL Label-->
      <b-modal ref="generatedhllabel" hide-footer hide-title centered size="lg">
        <DHLLabel />
      </b-modal>
    </b-container>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Generating your order. Please wait...</h3>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import DHLLabel from "./DHLLabel.vue";
import DHLTracking from "./DHLTracking.vue";
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";

export default {
  components: {
    MyTable,
    DHLLabel,
    flatPickr,
    DHLTracking,
  },

  data() {
    return {
      formattedDate: "",
      rows: [],
      isSubmitting: false,
      orderCancel: { order_id: "", cancel_reason: "" },
      generateDHLid: "",
      date: new Date().toISOString(),
      pageLength: 5,
      isLoading: false,
      columns: [
        {
          label: "Order ID",
          field: "id",
        },
        {
          label: "Pickup Date/Time",
          field: "pickup_date",
        },
        {
          label: "Sender's Name",
          field: "sender_location.first_name",
        },
        {
          label: "Recipient's Name",
          field: "recipient_location.first_name",
        },
        {
          label: "Origin Address",
          field: "sender_location.address_line_1",
        },
        {
          label: "Destination Address",
          field: "recipient_location.address_line_1",
        },
        {
          label: "Quantity",
          field: "quantity",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Actions",
          field: "action",
        },
      ],
      optionsCancel: {
        "Duplicate Order": "Duplicate Order",
        "Wrong Recipient's Address": "Wrong Recipient's Address",
        "Client Not Ready for Pick Up": "Client Not Ready for Pick Up",
        "Cancel Order": "Cancel Order",
        "IT Testing Order": "IT Testing Order",
      },
    };
  },

  created() {
    this.getOrders();
  },
  methods: {
    async getOrders(date = new Date().toISOString()) {
      this.isLoading = true;
      this.formattedDate = date.split("T")[0];
      const response = await this.$http.get(
        `orderminidc/filterOrders?order_type=DHL&pickup_date=${this.formattedDate}`
      );
      this.rows = response.data.data;
      this.isLoading = false;
    },
    generatedhl(id, index) {
      this.isSubmitting = true;
      this.$http.get(`generate/${id}`).then((response) => {
        if (response.status == 200) {
          if (response.data.status == true) {
            this.$swal({
              title: "DHL Generated !",
              text: response.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.rows[index].status = "Generated";
            this.$http
              .get(
                `orderminidc/filterOrders?order_type=DHL&pickup_date=${this.formattedDate}`
              )
              .then((response) => {
                this.rows = response.data.data;
              });
          } else {
            this.$swal({
              title: "Error !",
              text: response.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          this.isSubmitting = false;
        }
      });
    },
    generatedhltracking(id) {
      this.generateDHLid = id.shipment_id;
      this.$refs.generatedhltracking.show();
    },
    generatedhllabel(id) {
      location.href = id;
    },
    cancelOrder(id, index) {
      const reasons = this.optionsCancel;
      this.$swal({
        title: "Cancel Order",
        input: "select",
        inputOptions: reasons,
        inputPlaceholder: "Select Reason",
        inputAttributes: {
          "aria-label": "Select Reason",
        },
        inputValidator: (value) => !value && "Please give a value",
        allowOutsideClick: true,
        confirmButtonText: "SUBMIT",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.orderCancel.order_id = id;
          this.orderCancel.cancel_reason = result.value;
          this.$http.post(`cancel_order`, this.orderCancel).then((response) => {
            if (response.status == 200) {
              if (response.data.status == true) {
                this.$swal({
                  title: "Cancellation Accepted !",
                  icon: "success",
                  text: "Successfully cancelled the order",
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false,
                });
                this.rows[index].status = "Cancelled";
              } else {
                this.$swal({
                  title: "Cancellation Error !",
                  icon: "error",
                  text: response.data.message,
                  confirmButtonText: "Confirm",
                  customClass: {
                    confirmButton: "btn btn-warning",
                  },
                  buttonsStyling: false,
                });
              }
            }
          });
        }
      });
    },
    canceldhlgenerate(dhl) {
      axios;
      this.$http.get(`dhl_cancel/${dhl.shipment_id}`).then((response) => {
        if (response.status == 200) {
          if (response.data.message == "Shipment has already been cancelled.") {
            this.$swal({
              title: "Cancellation Accepted !",
              text: "Successfully cancelled the order",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "src/assets/scss/style.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
